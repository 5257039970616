import { inject } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { EGDSPrimaryButton, EGDSTertiaryButton } from "@egds/react-core/button";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSCardContentSection, EGDSCard, EGDSCardBackgroundThemes } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { AuthenticationState } from "bernie-context";
import { EGDSMark } from "@egds/react-core/mark";
import { ViewExtraLarge, ViewLarge, ViewMedium, ViewSmall, Viewport } from "@shared-ui/viewport-context";
import { FlexClickTracker } from "src/components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";
import { authUrlGenerator } from "./utils/authUrlGenerator";

interface MarkBrandMapType {
  expedia: string;
  lastminute?: string;
  orbitz: string;
  travelocity: string;
  wotif: string;
}

const PrimaryButtonTracked = FlexClickTracker(EGDSPrimaryButton);
const TertiaryButtonTracked = FlexClickTracker(EGDSTertiaryButton);

const MarkBrandMap: MarkBrandMapType = {
  expedia: "mod_exp",
  lastminute: undefined,
  orbitz: "mod_orb",
  travelocity: "mod_tvly",
  wotif: "mod_wotif",
};

const SWEDEN_SITE_ID = "65";
const SWITZERLAND_SITE_ID = "72";
const TEN_PERCENT_OFFER_SITES = [SWEDEN_SITE_ID, SWITZERLAND_SITE_ID];
const TEN = 10;
const FIFTEEN = 15;
const LOYALTY_SIGNIN = "loyatlySignup.singIn";
const LOYALTY_SIGNUP = "loyatlySignup.signUp";
const TITLE = "loyatlySignup.phase1.title";
const TITLE_ALT = "loyatlySignup.phase1.title.alternate";

interface LoyaltySignupProps {
  context?: ExtendedContextStore;
  classNames?: string;
}

export const LoyaltySignup: React.FC<LoyaltySignupProps> = inject("context")((props: LoyaltySignupProps) => {
  const { context } = props;
  const isAuth = context && context.user && context.user.authState !== AuthenticationState.ANONYMOUS;
  const location = useLocation();
  const { pathname, search } = location;

  const defaultUndefinedMark = "lastminute";
  const markName = MarkBrandMap[(context?.site?.brand as keyof MarkBrandMapType) ?? defaultUndefinedMark];

  const { formatText } = useLocalization();

  const percentageOff = TEN_PERCENT_OFFER_SITES.includes(context?.site?.id?.toString() ?? "") ? TEN : FIFTEEN;
  const altMsg = context?.site?.id?.toString() === SWITZERLAND_SITE_ID;
  const signUpTitleUpdate = altMsg ? TITLE_ALT : TITLE;
  const loyaltySignupTitle = formatText(signUpTitleUpdate, percentageOff);

  const linkConfigSignIn = {
    signInMessage: formatText(LOYALTY_SIGNIN),
    trackedLinkRffr: "loyaltySignup.actionLink.signIn",
    createGetUrl: authUrlGenerator("/user/signin?ckoflag=0&uurl=e3id%3Dredr%26rurl%3D%2F", pathname, search),
  };

  const linkConfigSignUp = {
    signUpMessage: formatText(LOYALTY_SIGNUP),
    trackedLinkRffr: "loyaltySignup.actionLink.signUp",
    createGetUrl: authUrlGenerator(
      "/signup?enable_registration=true&+=&uurl=e3id%3Dredr%26rurl%3D%2F",
      pathname,
      search
    ),
  };

  const isUsPosa = context?.site.pointOfSaleId === "EXPEDIA_US";

  if (isAuth) {
    return null;
  }

  return isUsPosa ? (
    <>
      <IntersectionTracker
        referrerId={"Hotels.loyaltySignup.actionLink.presented"}
        trackOnce
        linkName="loyalty-signup has been viewed"
      >
        <EGDSCard backgroundTheme={EGDSCardBackgroundThemes.GLOBAL_LOYALTY_STANDARD}>
          <EGDSCardContentSection>
            <EGDSSpacing
              margin={{ block: "three", inline: "three" }}
              padding={{
                large: { block: "half" },
                small: { block: "two" },
              }}
            >
              <EGDSLayoutFlex alignItems="center">
                {markName && (
                  <EGDSLayoutFlexItem alignSelf="start" minWidth={85}>
                    <div>
                      <EGDSSpacing
                        margin={{
                          small: { inlineend: "three" },
                          medium: { inlineend: "six" },
                          large: { inlineend: "six" },
                        }}
                        padding={{ inlineend: "half" }}
                      >
                        <div>
                          <Viewport>
                            <ViewSmall>
                              <EGDSMark
                                name={markName}
                                className="loyaltySignUpIcon"
                                url="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg"
                                size={11}
                              />
                            </ViewSmall>
                            <ViewMedium>
                              <EGDSMark
                                name={markName}
                                className="loyaltySignUpIcon"
                                url="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg"
                                size={11}
                              />
                            </ViewMedium>
                            <ViewLarge>
                              <EGDSMark
                                name={markName}
                                className="loyaltySignUpIcon"
                                url="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg"
                                size={11}
                              />
                            </ViewLarge>
                            <ViewExtraLarge>
                              <EGDSMark
                                name={markName}
                                className="loyaltySignUpIcon"
                                url="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg"
                                size={11}
                              />
                            </ViewExtraLarge>
                          </Viewport>
                        </div>
                      </EGDSSpacing>
                    </div>
                  </EGDSLayoutFlexItem>
                )}
                <EGDSLayoutFlexItem grow={1}>
                  <EGDSCardContentSection padded={false}>
                    <Viewport>
                      <ViewSmall>
                        <EGDSLayoutFlex
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          space="four"
                          wrap="wrap"
                        >
                          <EGDSLayoutFlexItem grow={1} minWidth="88x">
                            <EGDSLayoutFlex direction="column">
                              <EGDSSpacing padding={{ inlineend: "two" }}>
                                <div>
                                  {loyaltySignupTitle && (
                                    <EGDSLayoutFlexItem>
                                      <EGDSText theme="light" size={500} weight="medium">
                                        {loyaltySignupTitle}
                                      </EGDSText>
                                    </EGDSLayoutFlexItem>
                                  )}
                                </div>
                              </EGDSSpacing>
                            </EGDSLayoutFlex>
                          </EGDSLayoutFlexItem>
                          <EGDSLayoutFlex alignItems="center" space="three" justifyContent="end">
                            <EGDSLayoutFlexItem>
                              <PrimaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signin-button"
                                type="button"
                                href={linkConfigSignIn.createGetUrl()}
                                rfrr={linkConfigSignIn.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignIn.signInMessage}
                                </EGDSText>
                              </PrimaryButtonTracked>
                            </EGDSLayoutFlexItem>
                            <EGDSLayoutFlexItem>
                              <TertiaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signup-button"
                                type="button"
                                tag="a"
                                href={linkConfigSignUp.createGetUrl()}
                                inverse
                                rfrr={linkConfigSignUp.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignUp.signUpMessage}
                                </EGDSText>
                              </TertiaryButtonTracked>
                            </EGDSLayoutFlexItem>
                          </EGDSLayoutFlex>
                        </EGDSLayoutFlex>
                      </ViewSmall>
                      <ViewMedium>
                        <EGDSLayoutFlex
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          space="two"
                          wrap="nowrap"
                        >
                          <EGDSLayoutFlexItem grow={1} minWidth="88x">
                            <EGDSLayoutFlex direction="column">
                              <EGDSSpacing padding={{ inlineend: "two" }}>
                                <div>
                                  {loyaltySignupTitle && (
                                    <EGDSLayoutFlexItem>
                                      <EGDSText theme="light" size={500} weight="medium">
                                        {loyaltySignupTitle}
                                      </EGDSText>
                                    </EGDSLayoutFlexItem>
                                  )}
                                </div>
                              </EGDSSpacing>
                            </EGDSLayoutFlex>
                          </EGDSLayoutFlexItem>
                          <EGDSLayoutFlex alignItems="center" space="three" justifyContent="end">
                            <EGDSLayoutFlexItem>
                              <PrimaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signin-button"
                                type="button"
                                href={linkConfigSignIn.createGetUrl()}
                                rfrr={linkConfigSignIn.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignIn.signInMessage}
                                </EGDSText>
                              </PrimaryButtonTracked>
                            </EGDSLayoutFlexItem>
                            <EGDSLayoutFlexItem>
                              <TertiaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signup-button"
                                type="button"
                                tag="a"
                                href={linkConfigSignUp.createGetUrl()}
                                inverse
                                rfrr={linkConfigSignUp.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignUp.signUpMessage}
                                </EGDSText>
                              </TertiaryButtonTracked>
                            </EGDSLayoutFlexItem>
                          </EGDSLayoutFlex>
                        </EGDSLayoutFlex>
                      </ViewMedium>
                      <ViewLarge>
                        <EGDSLayoutFlex
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          space="two"
                          wrap="nowrap"
                        >
                          <EGDSLayoutFlexItem grow={1} minWidth="88x">
                            <EGDSLayoutFlex direction="column">
                              <EGDSSpacing padding={{ inlineend: "two" }}>
                                <div>
                                  {loyaltySignupTitle && (
                                    <EGDSLayoutFlexItem>
                                      <EGDSText theme="light" size={500} weight="medium">
                                        {loyaltySignupTitle}
                                      </EGDSText>
                                    </EGDSLayoutFlexItem>
                                  )}
                                </div>
                              </EGDSSpacing>
                            </EGDSLayoutFlex>
                          </EGDSLayoutFlexItem>
                          <EGDSLayoutFlex alignItems="center" space="three" justifyContent="end">
                            <EGDSLayoutFlexItem>
                              <PrimaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signin-button"
                                type="button"
                                href={linkConfigSignIn.createGetUrl()}
                                rfrr={linkConfigSignIn.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignIn.signInMessage}
                                </EGDSText>
                              </PrimaryButtonTracked>
                            </EGDSLayoutFlexItem>
                            <EGDSLayoutFlexItem>
                              <TertiaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signup-button"
                                type="button"
                                tag="a"
                                href={linkConfigSignUp.createGetUrl()}
                                inverse
                                rfrr={linkConfigSignUp.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignUp.signUpMessage}
                                </EGDSText>
                              </TertiaryButtonTracked>
                            </EGDSLayoutFlexItem>
                          </EGDSLayoutFlex>
                        </EGDSLayoutFlex>
                      </ViewLarge>
                    </Viewport>
                  </EGDSCardContentSection>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSSpacing>
          </EGDSCardContentSection>
        </EGDSCard>
      </IntersectionTracker>
    </>
  ) : (
    <>
      <IntersectionTracker
        referrerId={"Hotels.loyaltySignup.actionLink.presented"}
        trackOnce
        linkName="loyalty-signup has been viewed"
      >
        <EGDSCard backgroundTheme={EGDSCardBackgroundThemes.GLOBAL_LOYALTY_STANDARD}>
          <EGDSCardContentSection>
            <EGDSSpacing
              margin={{ block: "two", inline: "three" }}
              padding={{
                medium: { block: "one" },
                small: { block: "one" },
              }}
            >
              <EGDSLayoutFlex alignItems="center">
                {markName && (
                  <EGDSLayoutFlexItem alignSelf="start" minWidth={85}>
                    <div>
                      <EGDSSpacing
                        margin={{
                          small: { inlineend: "three" },
                          medium: { inlineend: "six" },
                          large: { inlineend: "six" },
                        }}
                        padding={{ inlineend: "half" }}
                      >
                        <div>
                          <Viewport>
                            <ViewSmall>
                              <EGDSMark
                                name={markName}
                                className="loyaltySignUpIcon"
                                url="https://a.travel-assets.com/pricing-claim/sparkle_dark.svg"
                                size={16}
                              />
                            </ViewSmall>
                            <ViewMedium>
                              <EGDSMark
                                name={markName}
                                className="loyaltySignUpIcon"
                                url="https://a.travel-assets.com/pricing-claim/sparkle_dark.svg"
                                size={18}
                              />
                            </ViewMedium>
                            <ViewLarge>
                              <EGDSMark
                                name={markName}
                                className="loyaltySignUpIcon"
                                url="https://a.travel-assets.com/pricing-claim/sparkle_dark.svg"
                                size={18}
                              />
                            </ViewLarge>
                            <ViewExtraLarge>
                              <EGDSMark
                                name={markName}
                                className="loyaltySignUpIcon"
                                url="https://a.travel-assets.com/pricing-claim/sparkle_dark.svg"
                                size={18}
                              />
                            </ViewExtraLarge>
                          </Viewport>
                        </div>
                      </EGDSSpacing>
                    </div>
                  </EGDSLayoutFlexItem>
                )}
                <EGDSLayoutFlexItem grow={1}>
                  <EGDSCardContentSection padded={false}>
                    <Viewport>
                      <ViewSmall>
                        <EGDSLayoutFlex
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          space="four"
                          wrap="wrap"
                        >
                          <EGDSLayoutFlexItem grow={1} minWidth="88x">
                            <EGDSLayoutFlex direction="column">
                              <EGDSSpacing padding={{ inlineend: "two" }}>
                                <div>
                                  {loyaltySignupTitle && (
                                    <EGDSLayoutFlexItem>
                                      <EGDSText theme="light" size={500} weight="medium">
                                        {loyaltySignupTitle}
                                      </EGDSText>
                                    </EGDSLayoutFlexItem>
                                  )}
                                </div>
                              </EGDSSpacing>
                            </EGDSLayoutFlex>
                          </EGDSLayoutFlexItem>
                          <EGDSLayoutFlex alignItems="center" space="three" justifyContent="end">
                            <EGDSLayoutFlexItem>
                              <PrimaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signin-button"
                                type="button"
                                href={linkConfigSignIn.createGetUrl()}
                                rfrr={linkConfigSignIn.trackedLinkRffr}
                              >
                                <EGDSText theme="light" size={400} weight="medium">
                                  {linkConfigSignIn.signInMessage}
                                </EGDSText>
                              </PrimaryButtonTracked>
                            </EGDSLayoutFlexItem>
                            <EGDSLayoutFlexItem>
                              <TertiaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signup-button"
                                type="button"
                                tag="a"
                                href={linkConfigSignUp.createGetUrl()}
                                inverse
                                rfrr={linkConfigSignUp.trackedLinkRffr}
                              >
                                <EGDSText theme="light" size={400} weight="medium">
                                  {linkConfigSignUp.signUpMessage}
                                </EGDSText>
                              </TertiaryButtonTracked>
                            </EGDSLayoutFlexItem>
                          </EGDSLayoutFlex>
                        </EGDSLayoutFlex>
                      </ViewSmall>
                      <ViewMedium>
                        <EGDSLayoutFlex
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          space="two"
                          wrap="nowrap"
                        >
                          <EGDSLayoutFlexItem grow={1} minWidth="88x">
                            <EGDSLayoutFlex direction="column">
                              <EGDSSpacing padding={{ inlineend: "two" }}>
                                <div>
                                  {loyaltySignupTitle && (
                                    <EGDSLayoutFlexItem>
                                      <EGDSText theme="light" size={500} weight="medium">
                                        {loyaltySignupTitle}
                                      </EGDSText>
                                    </EGDSLayoutFlexItem>
                                  )}
                                </div>
                              </EGDSSpacing>
                            </EGDSLayoutFlex>
                          </EGDSLayoutFlexItem>
                          <EGDSLayoutFlex alignItems="center" space="three" justifyContent="end">
                            <EGDSLayoutFlexItem>
                              <PrimaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signin-button"
                                type="button"
                                href={linkConfigSignIn.createGetUrl()}
                                rfrr={linkConfigSignIn.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignIn.signInMessage}
                                </EGDSText>
                              </PrimaryButtonTracked>
                            </EGDSLayoutFlexItem>
                            <EGDSLayoutFlexItem>
                              <TertiaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signup-button"
                                type="button"
                                tag="a"
                                href={linkConfigSignUp.createGetUrl()}
                                inverse
                                rfrr={linkConfigSignUp.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignUp.signUpMessage}
                                </EGDSText>
                              </TertiaryButtonTracked>
                            </EGDSLayoutFlexItem>
                          </EGDSLayoutFlex>
                        </EGDSLayoutFlex>
                      </ViewMedium>
                      <ViewLarge>
                        <EGDSLayoutFlex
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          space="two"
                          wrap="nowrap"
                        >
                          <EGDSLayoutFlexItem grow={1} minWidth="88x">
                            <EGDSLayoutFlex direction="column">
                              <EGDSSpacing padding={{ inlineend: "two" }}>
                                <div>
                                  {loyaltySignupTitle && (
                                    <EGDSLayoutFlexItem>
                                      <EGDSText theme="light" size={500} weight="medium">
                                        {loyaltySignupTitle}
                                      </EGDSText>
                                    </EGDSLayoutFlexItem>
                                  )}
                                </div>
                              </EGDSSpacing>
                            </EGDSLayoutFlex>
                          </EGDSLayoutFlexItem>
                          <EGDSLayoutFlex alignItems="center" space="three" justifyContent="end">
                            <EGDSLayoutFlexItem>
                              <PrimaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signin-button"
                                type="button"
                                href={linkConfigSignIn.createGetUrl()}
                                rfrr={linkConfigSignIn.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignIn.signInMessage}
                                </EGDSText>
                              </PrimaryButtonTracked>
                            </EGDSLayoutFlexItem>
                            <EGDSLayoutFlexItem>
                              <TertiaryButtonTracked
                                moduleName="Hotels"
                                data-testid="loyalty-signup-button"
                                type="button"
                                tag="a"
                                href={linkConfigSignUp.createGetUrl()}
                                inverse
                                rfrr={linkConfigSignUp.trackedLinkRffr}
                              >
                                <EGDSText whiteSpace="nowrap" theme="light" size={400} weight="medium">
                                  {linkConfigSignUp.signUpMessage}
                                </EGDSText>
                              </TertiaryButtonTracked>
                            </EGDSLayoutFlexItem>
                          </EGDSLayoutFlex>
                        </EGDSLayoutFlex>
                      </ViewLarge>
                    </Viewport>
                  </EGDSCardContentSection>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </EGDSSpacing>
          </EGDSCardContentSection>
        </EGDSCard>
      </IntersectionTracker>
    </>
  );
});

export default LoyaltySignup;
